
import React from "react"
import styled from "styled-components"

import Layout from "../layouts/layout"
import ImgBackground from "../components/imgBackground"

export default ({ data }) => (    
    <Layout>
        <FullPageDiv>
            <ImgBackground/>
            <h1>Oops</h1>
            <h1>We couldn't find the page you're looking for</h1>
        </FullPageDiv>
    </Layout>
)

const FullPageDiv = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    
    overflow: hidden;
`