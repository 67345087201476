import React from "react"

import styled from "styled-components"

const Bar = styled.div`
    width: 35px;
    height: 5px;
    background-color: white;
    margin-bottom: 6px;
    transition: 0.4s;

    &.botBar {
        margin-bottom: 0;
    }
`

const BarContainer = styled.div`
    .topBar.active {
        transform: rotate(-45deg) translate(-9px, 6px);
    }

    .midBar.active {
        opacity: 0;
    }

    .botBar.active {
        transform: rotate(45deg) translate(-8px, -8px);
    }
`

class BurgerIcon extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = { isActive: false };
        this.onIconClick = this.onIconClick.bind(this);
    }

    onIconClick()
    {
        this.setState({ isActive: !this.state.isActive })
    }

    render() {
        return (
            <BarContainer>
                <Bar className={`topBar ${this.state.isActive ? "active" : ""}`}/>
                <Bar className={`midBar ${this.state.isActive ? "active" : ""}`}/>
                <Bar className={`botBar ${this.state.isActive ? "active" : ""}`}/>
            </BarContainer>
        )
    }
}

export default BurgerIcon