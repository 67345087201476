import React from "react"
import styled from "styled-components"

import { SocialIcon } from 'react-social-icons';

export default () => {

    const style = { height: 35, width: 35 }

    return (
        <FooterContainer>
            <StyledSocialIcon url="https://www.facebook.com/thisisyourmusicalshow" bgColor="#ffffff" style={style}/>
            <StyledSocialIcon url="https://twitter.com/thisisyourmusic" bgColor="#ffffff" style={style}/>
            <StyledSocialIcon url="https://www.instagram.com/this_is_your_musical" bgColor="#ffffff" style={style}/>
        </FooterContainer>
    )
}

const FooterContainer = styled.footer`
    position: absolute;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: center;
`

const StyledSocialIcon = styled(SocialIcon)`
    margin: 1em 0.5em;

    opacity: 0.5;
    transition: opacity 200ms ease-in-out;

    :hover {
        opacity: 1.0;
    }
`