
import React from "react"

import { StaticQuery, graphql } from "gatsby"

import styled from 'styled-components'

import Img from "gatsby-image"

const ImgBackground = ({ className }) => (
    <StaticQuery query={graphql`
        query {
            desktop: file(relativePath: { eq: "TIYM-1.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 4160) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `}
        render={data => {
        // Set ImageData.
        const imageData = data.desktop.childImageSharp.fluid
        return (
            <Container className={className}>
                <StaticBackgroundImage
                fluid={imageData}>
                    <BlackBackground/>
                </StaticBackgroundImage>
            </Container>
        )
        }
        }
    />
    )

const Container = styled.div`
  width: 100%;
  height: inherit;
  position: absolute;

  background-color: black;

  z-index: -1;
`

const BlackBackground = styled.div`
    width: 100%;
    height: 100%;

    background-color: black;
    opacity: 0.5;
`

const StaticBackgroundImage = styled(Img)`
    ::before, ::after{
        background-position: center;
    }

    width: 100%;
    height: 100%;

    filter: blur(5px);
`

export default ImgBackground
