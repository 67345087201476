import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

import BurgerIcon from "../components/burgerIcon"

const HeaderLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1em;

  opacity: 0.5;

  h3 {
    margin: 0;
  }

  &.active {
    opacity: 1.0;
  }
`

const LinkWrapper = styled.div`
  position: fixed;
  top: 0;
  padding: 3em 0;

  height: 100%;
  width: 70%;
  min-width: 200px;
  max-width: 300px;

  background-color: #2a3a4a;

  transition: 0.5s;

  @media only screen and (min-width: 768px) {
    position: static;
    display: inherit;
    padding: 0;
    width: auto;

    background-color: transparent;

    transition: 0s;
  }
`

const StyledImg = styled(Img)`
  min-width: 100px;

  align-self: flex-start;
`

const NavBar = styled.nav`
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${StyledImg}, #burgerIcon {
    margin: 1em;
  }

  #burgerIcon {
    z-index: 10;

    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
`

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      menuPosition: "-100%"
    };
    this.handleClick = this.handleClick.bind(this);

    this.burgerIconElement = React.createRef();
  }
  
  handleClick() {

    this.setState({
      isActive: !this.state.isActive,
      menuPosition: this.state.isActive ? "-100%" : "0"
    })

    this.burgerIconElement.current.onIconClick();
  }

  render() {
    return (
      <StaticQuery query={graphql`
          query {
            file(relativePath: { eq: "TIYM-logo-short.png" }) {
              childImageSharp {
                fluid(maxWidth: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => {
            const imageData = data.file.childImageSharp.fluid
            return (
              <NavBar>
                <StyledImg fluid={imageData}/>
                <div onClick={this.handleClick} id="burgerIcon">
                  <BurgerIcon ref={this.burgerIconElement}></BurgerIcon>
                </div>
                <LinkWrapper style={{right:this.state.menuPosition}}>
                  <HeaderLink to="/" activeClassName="active"><h3>About</h3></HeaderLink>
                  <HeaderLink to="/contact" activeClassName="active"><h3>Contact</h3></HeaderLink>
                </LinkWrapper>
              </NavBar>
            )
          }
        }
      />
    )
  }
}

export default Header