import React from "react"
import {Helmet} from "react-helmet";

import styled, { createGlobalStyle } from 'styled-components'

import Header from "../components/header"
import Footer from "../components/footer"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;

    background-color: #1a2a3a;
  }
`

const MainContainer = styled.div`
  min-height: 100vh;
  height: auto;
  
  overflow: hidden;
  display: block;
  
  position: relative;
`

export default ({ children }) => (
  <MainContainer>
    <GlobalStyle/>

    <Helmet>
      <meta charset="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>

      <title>This Is Your Musical</title>

      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="description" content="This Is Your Musical is an improvised comedy musical theatre show associated with the Bristol Improv Theatre"/>
      <meta name="keywords" content="musical, theatre, comedy, improv, improvisation, Bristol, BIT, TIYM, This Is Your Musical, drama"/>
      <meta name="robots" content="index,follow"/>
      <meta name="googlebot" content="index,follow"/>
    </Helmet>

    <Header/>
      { children }
    <Footer/>
  </MainContainer>
)